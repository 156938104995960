import { Router, useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FourOhFour from '../components/common/FourOhFour';
import LoadingDialog from '../components/common/LoadingDialog';
import ConferenceDaysCheckoutPurchaseOrder from '../components/conference-days/ConferenceDaysCheckoutPurchaseOrder';
import ConferenceDaysCheckoutSuccess from '../components/conference-days/ConferenceDaysCheckoutSuccess';
import EventAssignTicketsWrapper from '../components/event-sections/EventAssignTicketsWrapper';
import EventSections from '../components/event-sections/EventSections';
import EventSectionsCheckout from '../components/event-sections/EventSectionsCheckout';
import AcceptDistrictInvitationWithCode from '../components/members/AcceptDistrictInvitation/AcceptDistrictInvitationWithCode';
import AcceptDistrictInvitationWithoutCode from '../components/members/AcceptDistrictInvitation/AcceptDistrictInvitationWithoutCode';
import AcceptOrganizationInvitationWithCode from '../components/members/AcceptOrganizationInvitation/AcceptOrganizationInvitationWithCode';
import AcceptOrganizationInvitationWithoutCode from '../components/members/AcceptOrganizationInvitation/AcceptOrganizationInvitationWithoutCode';
import AssignTickets from '../components/members/AssignTickets';
import Attendance from '../components/members/Attendance';
import AttendanceSection from '../components/members/AttendanceSection';
import EventApplicationAffiliationsAttendance from '../components/members/EventApplication/EventApplicationAffiliationsAttendance';
import EventApplicationCheckoutPurchaseOrder from '../components/members/EventApplication/EventApplicationCheckoutPurchaseOrder';
import EventApplicationCheckoutSuccess from '../components/members/EventApplication/EventApplicationCheckoutSuccess';
import EventApplicationGeneralInformation from '../components/members/EventApplication/EventApplicationGeneralInformation';
import EventApplicationQuestionnaire from '../components/members/EventApplication/EventApplicationQuestionnaire';
import EventApplicationReviewSubmit from '../components/members/EventApplication/EventApplicationReviewSubmit';
import EventApplications from '../components/members/EventApplication/EventApplications';
import EventApplicationSections from '../components/members/EventApplication/EventApplicationSections';
import EventRegistrationRequest from '../components/members/EventRegistration/EventRegistrationRequest';
import EventRegistrationRequestCheckoutSuccess from '../components/members/EventRegistration/EventRegistrationRequestCheckoutSuccess';
import EventRegistrationRequests from '../components/members/EventRegistration/EventRegistrationRequests';
import EventResources from '../components/members/EventResources';
import FormExample from '../components/members/FormExample';
import InstituteSelection from '../components/members/InstituteSelection';
import ManageOrganization from '../components/members/ManageOrganization/index';
import ManageServices from '../components/members/ManageServices';
import MySavedResources from '../components/members/MySavedResources';
import MyToolkit from '../components/members/MyToolkit';
import Organization from '../components/members/Organization';
import OrganizationResources from '../components/members/OrganizationResources';
import OrganizationSelect from '../components/members/OrganizationSelect';
import Packages from '../components/members/Packages';
import Profile from '../components/members/Profile';
import PurchaseOrders from '../components/members/PurchaseOrders';
import PurchaseOrdersInstitute from '../components/members/PurchaseOrdersInstitute';
import PurchaseOrdersPackage from '../components/members/PurchaseOrdersPackage';
import Review from '../components/members/Review';
import SchoolRoster from '../components/members/SchoolRoster';
import VideoCollection from '../components/members/VideoCollection';
import VideoCollectionContent from '../components/members/VideoCollectionContent';
import MembersLayout from '../components/MembersLayout';
import { AuthContext } from '../context/AuthContext';
import { getUserInfo } from '../util/auth';
import { setCookie } from '../util/cookie';
import EventManagement from './../components/members/EventManagement';
import Events from './../components/members/Events';
import EventSelection from './../components/members/EventSelection';
import GroupSelection from './../components/members/GroupSelection';
import StaffOptions from './../components/members/StaffOptions';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const Members = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MembersRoutes />
    </QueryClientProvider>
  );
};

const MembersRoutes = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [isHandlingToken, setIsHandlingToken] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has('token') && !isHandlingToken) {
      setIsHandlingToken(true);
      setCookie('access_token', params.get('token') as string);
      const refreshUser = async () => {
        const user = await getUserInfo();
        navigate('/members/profile', { replace: true }); // Use replace to prevent adding to history
      };
      refreshUser();
    }
  }, [location]);

  if (auth.authState === undefined || isHandlingToken) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingDialog />
      </div>
    );
  }

  return (
    <>
      <MembersLayout user={auth.authState}>
        <ToastContainer />
        <Router basepath="members">
          <FourOhFour default path="not-found" />
          <Profile path="profile" user={auth.authState} />
          <MyToolkit path="treasure-chest" />
          <FormExample path="form-example" />
          <MySavedResources path="my-saved-resources" />
          <AcceptOrganizationInvitationWithoutCode path="accept-organization-invitation" />
          <AcceptOrganizationInvitationWithCode path="accept-organization-invitation/:code" />
          <AcceptDistrictInvitationWithoutCode path="accept-district-invitation" />
          <AcceptDistrictInvitationWithCode path="accept-district-invitation/:code" />
          <PurchaseOrders path="purchase-orders">
            <PurchaseOrdersInstitute path="institute" />
            <PurchaseOrdersPackage path="package" />
          </PurchaseOrders>
          <EventSections path="event-cart">
            <EventSectionsCheckout path="checkout" />
            <ConferenceDaysCheckoutPurchaseOrder path="checkout/purchase-order" />
            <ConferenceDaysCheckoutSuccess path="checkout/success" />
          </EventSections>
          <EventAssignTicketsWrapper path="event/assign-tickets" />
          <OrganizationSelect path="organization/select" />
          <Organization path="organization/:organizationId">
            <ManageOrganization path="/" />
            <SchoolRoster path="school-roster" />
          </Organization>
          <ManageServices path="manage-services">
            <OrganizationSelect path="select-organization" />
            <Packages path="package-request/:packageRequestId">
              <StaffOptions path="staff-options" />
              <EventSelection path="event-selection" />
              <GroupSelection path="group-selection" />
              <InstituteSelection path="institute-selection" />
              <Review path="review" />
              <AssignTickets path="assign-tickets" />
            </Packages>
          </ManageServices>
          <Events path="events">
            <EventApplications path="applications" />
            <EventApplicationCheckoutPurchaseOrder path="applications/checkout/purchase-order" />
            <EventApplicationCheckoutSuccess path="applications/checkout/success" />
            <EventRegistrationRequestCheckoutSuccess path="registration/checkout/success" />
            <EventRegistrationRequest path="registration/requests/:requestId" />
            <EventRegistrationRequests path="registration/requests" />
            <EventManagement path="applications/:applicationId/manage">
              <EventApplicationGeneralInformation path="general-information" />
              <EventApplicationAffiliationsAttendance path="affiliations-and-attendance" />
              <EventApplicationSections path="sections" />
              <EventApplicationQuestionnaire path="questionnaire" />
              <EventApplicationReviewSubmit path="review-and-submit" />
            </EventManagement>
          </Events>

          <VideoCollection path="video-collection">
            <VideoCollectionContent path=":collectionName" />
          </VideoCollection>
          <Attendance path="events/:eventId/attendance" />
          <EventResources path="events/:eventId/resources" />
          <AttendanceSection path="events/:eventId/attendance/:sectionId" />
          <OrganizationResources path="organization-resources" />
        </Router>
      </MembersLayout>
    </>
  );
};

export default Members;
